import React, { ReactNode, useEffect } from 'react';

import SEO from '@/app/seo/SEO';
import { useContext } from './context';
import { ContentfulMeta } from '@/types';

/**
 * Hook to set page meta then revert when page is destroyed
 */
export const usePageMeta = (meta?: ContentfulMeta): ReactNode => {
  const [, dispatch] = useContext();
  useEffect(() => {
    dispatch({
      type: `SetPageMeta`,
      payload: meta,
    });
    return () => {
      dispatch({
        type: `SetPageMeta`,
        payload: undefined,
      });
    };
  }, [dispatch, meta]);
  return <SEO {...meta} />;
};
